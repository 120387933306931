import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import firebase from "../../firebase";
import "react-tabs/style/react-tabs.css";
import "./PowerBi.css";
import NavBar from "../../Components/NavBar";

const PowerBi = ({ setIsLoggedIn }) => {
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState("");

  useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      const data = await db.collection("powerbi").get();
      const arrayData = data.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(), 
        };
      });
      setData(arrayData.sort((a, b) => a.posicion - b.posicion));
    };
    obtenerDatos();
  }, []);
  useEffect(() => {
    const obtenerUser = async () => {
      const storedValue = await JSON.parse(localStorage.getItem("usuario"));
      if (storedValue) {
        setRoles(storedValue[0].rol);
      }
    };
    obtenerUser();
  }, []);
  return (
    <>
      <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      
      <div className="container mt-4">
        <Tabs>
          <TabList>
            {data.map(
              (item) =>
                item &&
                roles.map(
                  (rol) => rol === item.nombre && <Tab>{item.nombre}</Tab>
                )
            )}
          </TabList>

          {data.map(
            (item) =>
              item &&
              roles.map(
                (rol) =>
                  rol === item.nombre && (
                    <TabPanel>
                      <iframe
                        title="Report Section"
                        src={item.url}
                        frameborder="0"
                        allowFullScreen
                        
                      ></iframe>
                    </TabPanel>
                  )
              )
          )}
        </Tabs>
      </div>
    </>
  );
};

export default PowerBi;
