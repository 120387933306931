import React,{useEffect,useState} from 'react'
import { useNavigate } from "react-router-dom";
import useTablero from '../hooks/useTablero';
import CalidadAuditoria from '../pagina/CalidadAuditoria';
const NavBar = () => {
    const {isLoggedIn,setIsLoggedIn}=useTablero();

    const navigate = useNavigate();
    const [user, setUser] = useState("");
    const cerrarSession = () => {
      localStorage.setItem("loggin", false);
      setIsLoggedIn(localStorage.getItem("loggin"));
        navigate("/");
      };
    const cargamasiva = () =>{
      localStorage.setItem("loggin", true);
      setIsLoggedIn(localStorage.getItem("loggin"));
      navigate("/registromasivo");
    }
    const listaUsuarios = ()=>{
      localStorage.setItem("loggin", true);
      setIsLoggedIn(localStorage.getItem("loggin"));
      navigate("/listausuarios");
    }
    const PedidosMa = () => {
      localStorage.setItem("loggin", true);
      setIsLoggedIn(localStorage.getItem("loggin"));
      navigate("/pedidosma");
    }
    const beetrackPage = () =>{
      localStorage.setItem("loggin", true);
      setIsLoggedIn(localStorage.getItem("loggin"));
      navigate("/beetrack");
    }
      const CalidadAuditoria = () =>{
      localStorage.setItem("loggin", true);
      setIsLoggedIn(localStorage.getItem("loggin"));
      navigate("/calidadauditoria");
    }
    useEffect(() => {
        const obtenerUser = async () => {
          const storedValue = await JSON.parse(localStorage.getItem("usuario"));
          if (storedValue) {
            setUser(storedValue[0]);
          }
        };
        obtenerUser();
      }, []);
      const handleInicio = ()=>{
        navigate("/powerbi");
      }
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" onClick={()=>handleInicio()}>
              <h3>Bienvenido {user.nombres ? user.nombres : ''}</h3>
            </ul>
            <div className="form-inline my-2 my-lg-0">
            { user.acceso==='CALIDAD'  && (
              <button
                className="btn btn-danger my-2 my-sm-0 mr-2"
                onClick={CalidadAuditoria}
              >
               Auditoria Clientes Externos
              </button>
              )
              }
            { user.acceso==='ADMIN' && (
              <button
                className="btn btn-danger my-2 my-sm-0 mr-2"
                onClick={PedidosMa}
              >
               Pedidos Inforest
              </button>
              )
              }
            { user.acceso==='ADMIN' && (
              <button
                className="btn btn-danger my-2 my-sm-0 mr-2"
                onClick={beetrackPage}
              >
                Beetrack
              </button>
              

              )
              }
              { user.acceso==='ADMIN' && (
              <button
                className="btn btn-danger my-2 my-sm-0 mr-2"
                onClick={listaUsuarios}
              >
               Usuarios
              </button>
              )
              }
               { user.acceso==='ADMIN' && (
              <button
                className="btn btn-danger my-2 my-sm-0 mr-2"
                onClick={cargamasiva}
              >
                 Carga masiva
              </button>
              )
              }
              <button
                className="btn btn-danger my-2 my-sm-0"
                onClick={cerrarSession}
              >
                Cerrar sessión
              </button>
            </div>
          </div>
        </div>
      </nav>
  )
}

export default NavBar
