import emailjs from "@emailjs/browser";
import logo from '../file/img/logo-maria.svg';
import { useState,useEffect } from "react";
import firebase from "../firebase";
import { Link,useNavigate } from "react-router-dom";


const RecuperarContrasena = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [alert,setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [contrasena, setContrasena] = useState('');

  function generarContrasena() {
    const caracteres = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let contrasena = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * caracteres.length);
      contrasena += caracteres[randomIndex];
    }
    return contrasena;
  }
  useEffect(() => {
    const nuevaContrasena = generarContrasena();
    setContrasena(nuevaContrasena); 
  }, [])
  
  const enviarCorreo = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setMessage('Falta ingresar el correo electronico')
      setAlert(true)
      return;
    }
    try {
      const db = firebase.firestore();
      const data = await db.collection("usuarios").where("email","==",email).get();

      const arrayData = data.docs.map((doc) => {
        return {
           id: doc.id,
           ...doc.data(),
         };
     });
     if (arrayData.length === 0) {
      setAlert(true)
      setMessage('El correo no existe vuelve a intentar')
      return;
    }
    var templateParams = {
      username: arrayData[0].nombres,
      para: arrayData[0].email,
      message: contrasena,
    };

    const dataActu = await db.collection('usuarios').doc(arrayData[0].id);
    const updates = {
      password: contrasena,
      isnew: true
    };
    dataActu.update(updates);
    setAlert(true)
      setMessage('ok')
    await emailjs
     .send(
       "service_rxazzxl",
       "template_ez8vw1g",
       templateParams,
       "usFE8zsG63kmfxVKM"
     )
     .then(
       function (response) {
         console.log("SUCCESS!", response.status, response.text);
       },
       function (err) {
         console.log("FAILED...", err);
       }
     );
    } catch (error) {
      console.log(error);
    }
    
  };
  const volver = () => {
    navigate("/");
  }
  return (
    <div className="container">
      <div className="homePage d-flex justify-content-center align-items-center">
        <div className="col-xs-12 col-sm-12 col-md-5">
          <div className="container col-md-12 col-lg-12 mt-4">
          <Link to="/" ><img width="100%" height="50" src={logo}  alt="logo" className="mb-4 mt-4"/></Link> 
            <h2 className="form-signin-heading recuperarPassTitl text-center">
              Olvidaste tu contraseña{" "}
            </h2>
            <p className="subtreestablecerPass text-center">
              Enviaremos las instrucciones sobre cómo reestablecer tu
              contraseña a la dirección de correo electrónico asociada a tu
              cuenta.{" "}
            </p>

            <form className="form-signin panel center-block ">
              <h4 className="form-signin-heading siginsubTitl text-center">
                Por favor, escribe tu email{" "}
              </h4>
              <input
                type="email"
                className="form-control my-2"
                placeholder="Correo electrónico"
                onChange={(e)=>setEmail(e.target.value)}
                required
                autofocus
              />
              <button
                className="btn btn-lg btn-primary btn-block btnRecPass"
                onClick={enviarCorreo}
              >
                Recuperar contraseña
              </button>
              <button
                className="btn btn-lg btn-danger btn-block btnRecPass"
                onClick={volver}
              >
                Volver atraz
              </button>
              { alert && 
              
            
          <div
           className={`alert ${message!=='ok' ? "alert-danger" : "alert-success"} d-flex align-items-center mt-4`}
            role="alert"
          >
            { message!=='ok' &&
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
              viewBox="0 0 16 16"
              role="img"
              aria-label="Warning:"
              width={25}
            >
             
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
             }
            <div className="text-black">{message==='ok'? 'Se envio por correo las nuevas credenciales':message}</div>
          </div>
            }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecuperarContrasena;
