import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import Login from './auth/page/Login';
import PowerBi from './powerbi/page/PowerBi';
import ActualizarUser from './pagina/ActualizarUser';
import CargaUsuario from './pagina/CargaUsuario';
import ListaUsuarios from './pagina/ListaUsuarios';
import EditarUsuario from './pagina/EditarUsuario';
import CrearUsuario from './pagina/CrearUsuario';
import RecuperarContrasena from './pagina/RecuperarContrasena';
import PedidosMa from './pagina/PedidosMa';
import Beetrack from './pagina/Beetrack';
import CalidadAuditoria from './pagina/CalidadAuditoria';


const App = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('loggin') === 'true');
  useEffect(() => {

      
      setIsLoggedIn(localStorage.getItem("loggin"));

    }, [isLoggedIn]);

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn}/>} />
        <Route path="/powerbi" element={isLoggedIn ? <PowerBi /> : <Navigate to="/" replace />} />
        <Route path="/actualizar" element={isLoggedIn ? <ActualizarUser setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path="/registromasivo" element={isLoggedIn ? <CargaUsuario setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path="/listausuarios" element={isLoggedIn ? <ListaUsuarios setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path="/editusuario/:userId" element={isLoggedIn ? <EditarUsuario setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
      <Route path="/crearuser" element={isLoggedIn ? <CrearUsuario setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path='/recuperarcontrasena' element={<RecuperarContrasena />}></Route>
        <Route path='/pedidosma' element={isLoggedIn ? <PedidosMa setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path='/beetrack' element={isLoggedIn ? <Beetrack setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path='/calidadauditoria' element={isLoggedIn ? <CalidadAuditoria setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/" replace />} />
        <Route path="*" element={<Login setIsLoggedIn={setIsLoggedIn}/>} />
      </Routes>
  </Router>
  )
}


export default App
