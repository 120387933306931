import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../file/img/logo-maria.svg';
const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert,setAlert] = useState(false);
  const navigate = useNavigate();
 
  
  const validar = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      alert("Falta ingresar el correo electronico");
      return;
    }
    if (!password.trim()) {
      alert("Falta ingresar la contraseña");
      return;
    }
    try {
      const db = firebase.firestore();
      const data = await db.collection("usuarios").where("email","==",email).get();

      const arrayData = data.docs.map((doc) => {
        return {
           id: doc.id,
           ...doc.data(),
         };
     });
    if (arrayData.length === 0) {
        setAlert(true);
        return;
      }
    if(arrayData[0].password!==password){
      setAlert(true);
      return;
    }
    localStorage.setItem("usuario", JSON.stringify(arrayData));
    localStorage.setItem("loggin", true);
    setIsLoggedIn(localStorage.getItem("loggin"));
       if (arrayData[0].isnew === true) {
        navigate("/actualizar", { replace: true });
        } else {
          navigate("/powerbi", { replace: true });
        }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="row d-flex justify-content-center align-items-center mt-4">
        <div className="col-sm-12 col-md-4 mt-4">
          <img width="100%" height="50" src={logo}  alt="logo" className="mb-4"/>
          <h3 className="text-center mt-3 mb-4">Sistema GID</h3>
          <form onSubmit={validar}>
            <input
              type="email"
              className="form-control mb-2"
              placeholder="Ingrese Correo electrónico"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              type="password"
              className="form-control mb-2"
              placeholder="Ingrese Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
              <Link to="/recuperarcontrasena" className="link">¿Olvidaste tu contraseña?</Link>
            <button className="btn btn-dark btn-block mt-2">Iniciar sesión</button>
          </form>
         { alert && 
          <div
            className="alert alert-danger d-flex align-items-center mt-4"
            role="alert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
              viewBox="0 0 16 16"
              role="img"
              aria-label="Warning:"
              width={25}
            >
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div className="text-black">Datos ingresados no son los correctos ingrese nuevamente</div>
          </div>
            }
        </div>
      </div>
    </div>
  );
};

export default Login;
