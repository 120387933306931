import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBJ9E71xs8IbnUfZ0WNIKgswyQERg_sFUI",
  authDomain: "procesosgid-ad068.firebaseapp.com",
  projectId: "procesosgid-ad068",
  storageBucket: "procesosgid-ad068.appspot.com",
  messagingSenderId: "598708653870",
  appId: "1:598708653870:web:412e866ecb4fee499ef4bf"
};

 firebase.initializeApp(firebaseConfig);

export default firebase;

