import React, {useEffect, useState } from 'react'
import * as PedidosServicos from '../services/PedidosServices';
import NavBar from '../Components/NavBar';
const PedidosMa = ({setIsLoggedIn}) => {
    const [data,setData] = useState();
    const [order,setOrder] = useState();
    const [resInforest,setResInforest] = useState();
    
        const hindlePedido = () =>{
            PedidosServicos.getPedidoId(order,setData);
        }
        const hindleInforest = () =>{
           const respuesta=PedidosServicos.postInforest(data.local,data);
           console.log(respuesta);
        }
 return (
    <>
    <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
    <h1 className='text-center mt-3'>Inforest</h1>
    <div className='row w-100'>
        <div className="col col-md-8 col-sm-12 container flex-md-row">
            <div className='row container flex-column flex-md-row'>
                <div className='col col-md-4 col-sm-12'>
                    <div className="form-group">
                        <input 
                        type="text" 
                        className="form-control "
                         name='npedido' 
                         id="npedido" 
                         placeholder='Ingrese numero de pedido'
                         onChange={(e)=>setOrder(e.target.value)}
                         />
                    </div>
                </div>
                <div className='col col-md-3 col-sm-12 text-center text-md-left'>
                    <button type="button" className="btn btn-danger btn-xl" onClick={()=>hindlePedido()}>
                        Consular Pedido
                    </button>
                </div>
            </div>
            { data && 
            <div className='container'>
                <div className='row'>
                    <div className='col col-md-3 col-sm-12'>
                        Local: <input type="text" value={data.local===1 ? 'LA MAR' : data.local===2 ? '2 DE MAYO' : data.local===3 ? 'STA CATALINA': data.local===4 ? 'PASO 28' : data.local===5 ? 'ARAMBURÚ':data.local===6 ? 'LA ENCALADA':data.local===7 ? 'LA MOLINA':data.local===8 ? 'SAN MIGUEL':data.local===9 ? 'CAMACHO':data.local===10 ? 'MEGA PLAZA - IND':data.local===11 ? 'CHORILLOS':data.local===12 ? 'LA MARINA':data.local===13 ? 'PRECURSORES': data.local===14 ? 'BOLIVAR':data.local} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        Nombre: <input type="text" value={data.nombre} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        Apellido: <input type="text"value={data.apellido} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        Correo: <input type="text"value={data.correoElectronico} className='w-100'/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-md-6 col-sm-12'>
                        Direccion: <input type="text" value={data.direccion} className='w-100'/>
                    </div>
                    <div className='col col-md-6 col-sm-12'> 
                        <div className="form-group">
                        Observacion: 
                        <input type="text"value={data.observacion} className='w-100'/>
                        </div>
                    </div>
                </div>
                <div className='row'>
             {data.detallePedido &&
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">ITEM</th>
                        <th scope="col">CODIGO PRODUCTO</th>
                        <th scope="col">CANTIDAD</th>
                        <th scope="col">COMBO</th>
                        <th scope="col">OBSERVACION</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.detallePedido.map( (pedido) =>
                        <tr>
                        <th scope="row">{pedido.item}</th>
                        <td>{pedido.codigoproducto}</td>
                        <td>{pedido.cantidad}</td>
                        <td>{pedido.lcombo}</td>
                        <td>{pedido.observacion}</td>
                        </tr>
                        ) }
                    </tbody>
                    </table>
                    }
                </div>
                <div className='row'>
             {data.prepagos &&
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">TIPO PAGO</th>
                        <th scope="col">MONTO</th>
                        <th scope="col">VUELTO</th>
                        <th scope="col">TARJETA</th>
                        <th scope="col">NUMERO</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.prepagos.map( (prepago) =>
                        <tr>
                        <th scope="row">{prepago.tipopago==='02'? 'Pago Online ecommerce':'No se registro correctamente'}</th>
                        <td>{prepago.monto}</td>
                        <td>{prepago.vuelto}</td>
                        <td>{prepago.tarjeta ==='17' ? 'Izipa e-ecommerce' : 'No se registro correctamente'}</td>
                        <td>{prepago.numero}</td>
                        </tr>
                        ) }
                    </tbody>
                    </table>
                    }
                </div>
                <div className='row'>
                <div className='col col-md-3 col-sm-12'>
                    Tipo Documento: <input type="text"value={data.tipodocumento==='02' ? 'BOLETA' : data.tipodocumento==='01' ? 'FACTURA' : ''} className='w-100'/>
                    </div>
                <div className='col col-md-3 col-sm-12'>
                    Tipo Identidad: <input type="text"value={data.tipoIdentidad==='1' ? 'DNI' : data.tipoIdentidad==='4' ? 'CARNET DE EXTRANJERÍA' : data.tipoIdentidad==='6' ? 'REGISTRO UNICO CONTRIBUYENTES (RUC)': data.tipoIdentidad==='7' ? 'PASAPORTE': data.tipoIdentidad==='A' ? 'CEDULA DIPLOMATICA DE IDENTIDAD' : ''} className='w-100'/>
                    </div>

                    <div className='col col-md-3 col-sm-12'>
                    ruc: <input type="text" value={data.ruc} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        telefono: <input type="text" value={data.telefono} className='w-100'/>
                    </div>
                    
                </div>
                <div className='row'>
                <div className='col col-md-3 col-sm-12'>
                    Razon Social  <input type="text"value={data.razonsocial} className='w-100'/>
                    </div>
                <div className='col col-md-3 col-sm-12'>
                    Tipo Pedido: <input type="text"value={data.tipopedido==='02' ? 'Delivey' : data.tipopedido==='03' ? 'Pickup' : 'Viajo mal el dato' } className='w-100'/>
                    </div>

                    <div className='col col-md-3 col-sm-12'>
                    ubigeo: <input type="text" value={data.ubigeo} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                    urbanizacion: <input type="text" value={data.urbanizacion} className='w-100'/>
                    </div>
                    
                </div>
            </div>
            }
            <div className='text-center'>
            { data &&
        <button className='text-center btn btn-danger mt-4 mb-5'
        type='submit'
        onClick={()=>hindleInforest()}
        >
            Ingresar pedido a inforest
        </button>
        }
        {resInforest ? resInforest :''}
        </div>
        </div>
 
    </div>
    </>
 )
}

export default PedidosMa