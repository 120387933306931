import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../Components/NavBar";

const EditarUsuario = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState("");
  const [roles, setRoles] = useState("");
  const [data, setData] = useState("");
  const [nombres, setNombres] = useState("");
  const [email, setEmail] = useState("");
  const [restablecer, setRestablecer] = useState("");
  const [selectRol, setSelectRol] = useState("");
  useEffect(() => {
    const obtenerUser = async () => {
      const storedValue = await JSON.parse(localStorage.getItem("usuario"));
      if (storedValue) {
        setUser(storedValue[0]);
      }
    };
    obtenerUser();
  }, []);
  useEffect(() => {
    const getUser = async () => {
      try {
        const db = firebase.firestore();
        const docRef = await db.collection("usuarios").doc(userId).get();
        setData(docRef.data());
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [userId]);
  useEffect(() => {
    setNombres(data.nombres);
    setEmail(data.email);
    setRestablecer(data.isnew);
    setSelectRol(data.rol);
  }, [data]);
  useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      const data = await db.collection("powerbi").get();
      const arrayData = data.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setRoles(arrayData.sort((a, b) => a.posicion - b.posicion));
    };
    obtenerDatos();
  }, []);
  const cerrarSession = () => {
    setIsLoggedIn(false);
    navigate("/");
  };
  const handleRoleSelection = (event) => {
    const roleName = event.target.value;
    if (selectRol.includes(roleName)) {
      // The role is already selected, so remove it from the list
      setSelectRol(selectRol.filter((name) => name !== roleName));
    } else {
      // The role is not selected, so add it to the list
      setSelectRol([...selectRol, roleName]);
    }
  };
  const actualizar = async (e) =>{
    e.preventDefault();
    if(selectRol.length===0){
        alert("Debe elegir al menos un Rol");
        return;
    }
    try {
      const updateUser =  {
        nombres: nombres ? nombres: '',
        isnew:restablecer,
        rol:selectRol
      }
        const db = firebase.firestore();
        await db.collection('usuarios').doc(userId).update(updateUser);
        alert("Se actualizo datos del usuario");
        setIsLoggedIn(true);
        navigate("/listausuarios", { replace: true });
    } catch (error) {
        console.log(error);
    }
  }
  return (
    <>
      <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      <div className="container mt-4" key={userId}>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4 ">
            <h1 className="text-center mb-3">Actualizar información</h1>
            <form onSubmit={actualizar}>
              <div className="form-group">
                <label for="correo">Correo</label>
                <input
                  type="text"
                  className="form-control"
                  id="correo"
                  disabled
                  value={email}
                />
              </div>
              <div className="form-group">
                <label for="nombres">Nombres y apellidos</label>
                <input
                  type="text"
                  className="form-control"
                  id="nombres"
                  placeholder="Ingrese sus nombres y apellidos"
                  onChange={(e) => setNombres(e.target.value)}
                  value={nombres}
                />
              </div>
              <div className="form-group">
                <label for="restablecer">Restablecer contraseña</label>
                <select
                  onChange={(e) => setRestablecer(e.target.value)}
                  className="form-select"
                  aria-label="Seleccionar"
                >
                  {restablecer ? (
                    <option value={true} selected="true">
                      No
                    </option>
                  ) : (
                    <option value={false} selected="true">
                      Si
                    </option>
                  )}
                  {restablecer ? (
                    <option value={false}>Si</option>
                  ) : (
                    <option value={true}>No</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label for="restablecer">Roles</label>
                {roles.length > 0 &&
                  roles.map(
                    (rol) =>
                      rol && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={rol.nombre}
                            id="flexCheckDefault"
                            onChange={handleRoleSelection}
                            checked={selectRol && selectRol.includes(rol.nombre)}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {rol.nombre}
                          </label>
                        </div>
                      )
                  )}
              </div>
              <button className="btn btn-dark btn-block">Actualizar</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditarUsuario;
