import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import { useNavigate } from "react-router-dom";
import NavBar from "../Components/NavBar";

const ActualizarUser = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [roles, setRoles] = useState("");
  const [nombres, setNombres] = useState("");
  const [password, setPassword] = useState("");
  const [id,setId] = useState("");
  useEffect(() => {
    const obtenerUser = async () => {
      const storedValue = await JSON.parse(localStorage.getItem("usuario"));
      if (storedValue) {
        setUser(storedValue[0]);
        setRoles(storedValue[0].rol);
        setId(storedValue[0].id)
      }
    };
    setNombres('');
    setPassword('');
    obtenerUser();
  }, []);
  const cerrarSession = () => {
    setIsLoggedIn(false);
    navigate("/");
  };
  const actualizar = async (e) =>{
    e.preventDefault();
    if(!nombres.trim()){
      alert("Falta ingresar sus nombres y apellidos");
      return
    }
    if(!password.trim()){
      alert("Falta ingresar su contraseña");
      return
    }
      try {
        const db = firebase.firestore();
        const data = await db.collection('usuarios').doc(id);
        const updates = {
          nombres: nombres,
          password: password,
          isnew: false
        };
        data.update(updates);
        alert("Actualizado correctamente");
        navigate('/', { replace: true });
      } catch (error) {
        console.log(error);
      }
  }
  return (
    <>
     <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4 ">
            <h1 className="text-center mb-3">Actualizar información</h1>
            <form onSubmit={actualizar}>
              <div className="form-group">
                <label for="correo">Correo</label>
                <input
                  type="text"
                  className="form-control"
                  id="correo"
                  disabled
                  value={user.email}
                />
              </div>
              <div className="form-group">
                <label for="nombres">Nombres y apellidos</label>
                <input
                  type="text"
                  className="form-control"
                  id="nombres"
                  placeholder="Ingrese sus nombres y apellidos"
                  onChange={(e) => setNombres(e.target.value)}
                  value={nombres}
                />
              </div>
              <div className="form-group">
                <label for="contrasena">Contraseña</label>
                <input
                  type="password"
                  className="form-control"
                  id="contrasena"
                  placeholder="Ingrese nueva contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <button className="btn btn-dark btn-block">Actualizar</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActualizarUser;
