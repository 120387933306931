import axios from "axios";
import {urlLocal,urlinforest} from "../config";

export async function getPedidoId(id,setValue) {
    var data = '';
    var config = {
        method: 'get',
        url: `${urlLocal}pedido/list/${id}`,
        headers: {},
        data : data
      };
    const response = await axios(config)
    setValue(response.data)
}

export const postInforest = async (local, pedido) => {
  const url = `${urlinforest}WSMariaAlmenaraPrueba/api/Pedido/InsertarPedidoWeb2?local=${local}`;
  const data = JSON.stringify(pedido);
  const config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  try {
    const response = await axios(config);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};