import React, {useEffect, useState } from 'react'
import * as BeetrackServicos from '../services/BeetrackServices';
import NavBar from '../Components/NavBar';
const Beetrack = ({setIsLoggedIn}) => {
    const [data,setData] = useState();
    const [order,setOrder] = useState();
    const [resBeetrack,setResBeetrack] = useState('');
    const [boton,setBoton] = useState(false);
        const hindlePedido = () =>{
            BeetrackServicos.getPedidoId(order,setData);
        }
         const insertarBeetrack = () =>{
            setBoton(true);
          BeetrackServicos.insertarBeetrackPedido(data,setResBeetrack);
         
         }
 return (
    <>
    <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
    <h1 className='text-center mt-3'>Insertar Beetrack</h1>

    <div className='row w-100'>
        <div className="col col-md-8 col-sm-12 container flex-md-row">
            <div className='row container flex-column flex-md-row'>
                <div className='col col-md-4 col-sm-12'>
                    <div className="form-group">
                        <input 
                        type="text" 
                        className="form-control "
                         name='npedido' 
                         id="npedido" 
                         placeholder='Ingrese numero de pedido'
                         onChange={(e)=>setOrder(e.target.value)}
                         />
                    </div>
                </div>
                <div className='col col-md-3 col-sm-12 text-center text-md-left'>
                    <button type="button" className="btn btn-danger btn-xl" onClick={()=>hindlePedido()}>
                        Consular Pedido
                    </button>
                </div>
            </div>
            { data && 
            <div className='container'>
                { data && (
                <div className='row'>
                    <div className='col col-md-3 col-sm-12'>
                        Identificados pedido: <input type="text" value={data.identifier} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        Tipo de envio: <input type="text" value={data.is_pickup?'Pickup':'Delivery'} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        Nombres: <input type="text"value={data.contact_name} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                        Correo: <input type="text"value={data.contact_email} className='w-100'/>
                    </div>
                </div>
                   ) }
                <div className='row'>
                    <div className='col col-md-6 col-sm-12'>
                        Direccion: <input type="text" value={data.contact_address} className='w-100'/>
                    </div>
                    <div className='col col-md-6 col-sm-12'> 
                        <div className="form-group">
                        <a href={"https://www.google.com/maps/search/?api=1&query="+data.latitude+","+data.longitude+""} target="_blank"> Latitud y Longitud</a>
                        <input type="text" value={"Latitud: "+data.latitude+" Longitud: "+data.longitude} className='w-100'/>
                        </div>
                    </div>
                </div>
                <div className='row'>
             {data.items &&
                <table class="table table-striped">
                    <thead>
                        <tr>
                
                        <th scope="col">CODIGO PRODUCTO</th>
                        <th scope="col">NOMBRE DEL PRODUCTO</th>
                        <th scope="col">CANTIDAD</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.items.map( (pedido) =>
                        <tr>
                        <th scope="row">{pedido.code}</th>
                        <td>{pedido.description}</td>
                        <td>{pedido.quantity}</td>
                        </tr>
                        ) }
                    </tbody>
                    </table>
                    }
                </div>
                <div className='row'>
             {data.prepagos &&
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">TIPO PAGO</th>
                        <th scope="col">MONTO</th>
                        <th scope="col">VUELTO</th>
                        <th scope="col">TARJETA</th>
                        <th scope="col">NUMERO</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.prepagos.map( (prepago) =>
                        <tr>
                        <th scope="row">{prepago.tipopago==='02'? 'Pago Online ecommerce':'No se registro correctamente'}</th>
                        <td>{data.load}</td>
                        <td>{prepago.vuelto}</td>
                        <td>{prepago.tarjeta ==='17' ? 'Izipa e-ecommerce' : 'No se registro correctamente'}</td>
                        <td>{prepago.numero}</td>
                        </tr>
                        ) }
                    </tbody>
                    </table>
                    }
                </div>
                <div className='row'>
                <div className='col col-md-3 col-sm-12'>
                    Numero Documento: <input type="text"value={data.contact_id} className='w-100'/>
                    </div>
                <div className='col col-md-3 col-sm-12'>
                    Tiempo de Servicio: <input type="text"value={data.service_time} className='w-100'/>
                    </div>

                    <div className='col col-md-3 col-sm-12'>
                    Prioridad: <input type="text" value={data.priority} className='w-100'/>
                    </div>
                    <div className='col col-md-3 col-sm-12'>
                    Costo: <input type="text" value={data.load} className='w-100'/>
                    </div>
                    
                </div>
                <div className='row'>
             { data.tags &&
                <table class="table table-striped">
                    <thead>
                        <tr>
                        { data.tags.map( (tag) =>
                        <th scope="col">{tag.name}</th>
                        )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        { data.tags.map( (tag) =>
                         <td>{tag.value}</td> 
                         ) }
                        </tr>
                    </tbody>
                    </table>
                    }
                </div>
            </div>
            }
            <div className='text-center'>
         { data && (
         <button className='text-center btn btn-danger mt-4 mb-5 '
        type='submit'
        onClick={()=>insertarBeetrack()}
        >
            Ingresar pedido a Beetrack
        </button>
        )   }
        {boton && 
        (resBeetrack ? 'Se inserto correctamente' :'Algo paso No se inserto correctamente')
         }
        </div>
        </div>
 
    </div>
    </>
 )
}

export default Beetrack