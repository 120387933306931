import { createContext, useState } from "react"

const TableroContext = createContext();
const TablerosProvider = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

 return (
        <TableroContext.Provider
        value={{
            isLoggedIn,
            setIsLoggedIn,
        }}
        >
            {children}
        </TableroContext.Provider>
 )
}

export {
    TablerosProvider
}
export default TableroContext;