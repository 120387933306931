import React from "react";
import { Chart } from "react-google-charts";
import NavBar from "../Components/NavBar";

const Table = ({ data }) => {
    return (
        <div className="container">
      <table className="custom-table">
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Año</th>
            <th>Fecha</th>
            <th>%</th>
            <th>Motivo</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.cliente}</td>
              <td>{row.año}</td>
              <td>{row.fecha}</td>
              <td>{row.porcentaje}</td>
              <td>{row.motivo}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    );
  };
const CalidadAuditoria = ({ setIsLoggedIn }) => {
    const data = [
        ["Año", "Porcentaje", { role: "style" }, { role: "annotation" }],
        ["PROCESO CHORRILLOS\n2021", 94, "color: #007a93", "94%\n\n\nCLIENTE SBUX"], 
        ["ALMACEN SAN LUIS\n2022", 97, "color: #00b259", "97%\n\n\nCLIENTE SBUX"], 
        ["PROCESO SAN LUIS\n2022", 96.50, "color: #fea57f", "96.50%\n\n\nCLIENTE SBUX"],
        ["PROCESO Y ALMACEN\n2024", 96.75, "color: #7dd5f0", "96.75%\n\n\nCLIENTE SBUX"], 
        ["PROCESO Y ALMACEN\n2024", 87.27, "color: #7c55f0", "87.27%\n\n\nCLIENTE NGR"],
        ["", 0, "color: #7c55f0", ""]

    ];
const data2 = [
  { cliente: "SBUX", año: "2021", fecha: "26/04/2021", porcentaje: "94%", motivo: "Seguimiento Anual" },
  { cliente: "SBUX", año: "2022-1", fecha: "18/08/2022", porcentaje: "97%", motivo: "Seguimiento Anual" },
  { cliente: "SBUX", año: "2022-II", fecha: "28/12/2022", porcentaje: "96.50%", motivo: "Seguimiento Anual" },
  { cliente: "SBUX", año: "2024", fecha: "5/01/2024", porcentaje: "96.75%", motivo: "Seguimiento Anual" },
  { cliente: "NGR", año: "2024", fecha: "27/02/2024", porcentaje: "87.27%", motivo: "Auditoria de Inicio" },
];
  const options = {
    chartArea: { width: "50%"},
    hAxis: { title: "Detalle" },
    vAxis: { title: "Porcentaje", format: "##,##%" }, // Formato de porcentaje
    legend: { position: "none" },
    annotations: {
      textStyle: {
        fontSize: 12,
        bold: true,
        color: "#000",
      },
    },
  };

  return (
    <>
      <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      <h1 className='text-center mt-4'>RESULTADOS AUDITORIAS MA CLIENTES EXTERNOS<br />2021-2024</h1>
      <Chart chartType="ColumnChart" width="100%" height="400px" data={data} options={options} />
        <br></br><br></br>
        <h1 className='text-center mt-3'> AUDITORIAS EXTERNAS DE CLIENTES- MA	</h1>		
        <br></br>		

      <Table data={data2} />
      <br></br>
    </>
  );
}

export default CalidadAuditoria;
