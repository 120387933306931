import axios from "axios";
import {urlLocal,urlinforest} from "../config";

export async function getPedidoId(id,setValue) {
    var data = '';
    var config = {
        method: 'get',
        url: `${urlLocal}pedidoBeetrack/list/${id}`,
        headers: {},
        data : data
      };
    const response = await axios(config)
    setValue(response.data)
}


export async function insertarBeetrackPedido(pedido,setValor){
    const url = `${urlLocal}pedidoBeetrack/insert`;
    const data = JSON.stringify(pedido);
    const config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    const response = await axios(config);
    setValor(response.data);
  };
