import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import { useNavigate } from "react-router-dom";
import Papa from 'papaparse';
import NavBar from "../Components/NavBar";

const CargaUsuario = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [roles, setRoles] = useState("");
  const [id,setId] = useState("");
  useEffect(() => {
    const obtenerUser = async () => {
      const storedValue = await JSON.parse(localStorage.getItem("usuario"));
      if (storedValue) {
        setUser(storedValue[0]);
        setRoles(storedValue[0].rol);
        setId(storedValue[0].id)
      }
    };
    obtenerUser();
  }, []);
  function parseCSV(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  const saveData = async (data) => {
    try {
        const db = firebase.firestore();
          data.map((item) => item &&  db.collection('usuarios').add(
            {
                isnew:JSON.parse(item.isnew),
                email:item.email,
                password:item.password,
                rol:item.rol.split(",")
            }
          ));
          alert("Se cargo con exito");
    } catch (error) {
        console.log(error);
    }

  }
  function handleUpload(event) {
    const file = event.target.files[0];
    parseCSV(file).then((data) => {
      saveData(data);
    });
  }
 
  return (
    <>
      <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-4 ">
            <h1 className="text-center mb-3">Registro Masivo</h1>
                <input type="file" onChange={handleUpload} />              
          </div>
        </div>
      </div>
    </>
  );
};

export default CargaUsuario;
