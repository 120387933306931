import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import NavBar from "../Components/NavBar";

const ListaUsuarios = ({ setIsLoggedIn }) => {
  const [user, setUser] = useState("");
  const [list, setList] = useState("");
  const [email,setEmail] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const obtenerUser = async () => {
      const storedValue = await JSON.parse(localStorage.getItem("usuario"));
      if (storedValue) {
        setUser(storedValue[0]);
      }
    };
    obtenerUser();
  }, []);
  useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      const data = await db.collection("usuarios").get();
      const arrayData = data.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setList(arrayData);
    };
    obtenerDatos();
  }, []);

  const editar = async (id) => {
    setIsLoggedIn(true);
    await navigate("/editusuario/" + id);
  };
const crear = () =>{
  setIsLoggedIn(true);
  navigate("/crearuser");
}
const buscar = async (e) =>{
    e.preventDefault();
    setEmail(e.target.value);
    try {
      const db = firebase.firestore();
      const datae =  await db.collection("usuarios").where('email', '>=', email)
      .where('email', '<=', email + '\uf8ff')
      .get();
      if (datae.empty || datae.length===0) {
        // Consulta para retornar todos os documentos na coleção
        const allDocsSnapshot = await db.collection("usuarios").get();
        const arrayDatay = allDocsSnapshot.docs.map((doc) => {
          return {
             id: doc.id,
             ...doc.data(),
           };
        });  
        setList(arrayDatay);     
        return;
      }
      const arrayDatax = datae.docs.map((doc) => {
        return {
           id: doc.id,
           ...doc.data(),
         };
      });
      setList(arrayDatax);   
      return   
    } catch (error) {
      console.log(error);
    }
}


  return (
    <>
      <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <button className="btn btn-success" onClick={crear}>Crear nuevo usuario</button>
            <h1 className="text-center mb-3">Lista de usuarios</h1>
            <div className="col-sm-12 col-md-4 mx-0 px-0">
            <div className="form-group">
         <input type="text" class="form-control" onChange={(e)=>buscar(e)} value={email} placeholder="Ingresar el correo"/>
            </div>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Email</th>
                  <th scope="col">Nombres</th>
                  <th scope="col">Rol</th>
                  <th scope="col">Activo cuenta</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 &&
                  list.map(
                    (item, indice) =>
                      item && (
                        <tr>
                          <th scope="row" key={indice}>
                            {indice + 1}
                          </th>
                          <td>{item.email}</td>
                          <td>{item.nombres}</td>
                          <td>{item.rol.join(", ")}</td>
                          <td>{item.isnew === true ? "NO" : "SI"}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => editar(item.id)}
                            >
                              EDITAR
                            </button>
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListaUsuarios;
