import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import { useNavigate } from "react-router-dom";
import NavBar from "../Components/NavBar";

const CrearUsuario = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [roles, setRoles] = useState("");
  const [nombres, setNombres] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [restablecer, setRestablecer] = useState(true);
  const [selectRol, setSelectRol] = useState("");
  useEffect(() => {
    const obtenerUser = async () => {
      const storedValue = await JSON.parse(localStorage.getItem("usuario"));
      if (storedValue) {
        setUser(storedValue[0]);
      }
    };
    obtenerUser();
  }, []);

  useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      const data = await db.collection("powerbi").get();
      const arrayData = data.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setRoles(arrayData.sort((a, b) => a.posicion - b.posicion));
    };
    obtenerDatos();
  }, []);
  const cerrarSession = () => {
    setIsLoggedIn(false);
    navigate("/");
  };
  const handleRoleSelection = (event) => {
    const roleName = event.target.value;
    if (selectRol.includes(roleName)) {
      // The role is already selected, so remove it from the list
      setSelectRol(selectRol.filter((name) => name !== roleName));
    } else {
      // The role is not selected, so add it to the list
      setSelectRol([...selectRol, roleName]);
    }
  };
  const registrarUser = async (e) =>{
    e.preventDefault();
    if(!email){
      alert("De ingresar correo");
      return;
    }
   try {
    const insert = {
      nombres:nombres ? nombres: '',
      email:email,
      password:password ? password : 'T123456T',
      isnew: restablecer,
      rol:selectRol
    } 
    const db=firebase.firestore();
     await db.collection('usuarios').add(insert);
     alert("Se registro correctamente");
     setIsLoggedIn(true);
     navigate("/listausuarios", { replace: true });
   } catch (error) {
    console.log(error);
   }
   
  }

  return (
    <>
     <NavBar setIsLoggedIn={setIsLoggedIn}></NavBar>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4 ">
            <h1 className="text-center mb-3">Crear usuario</h1>
            <form onSubmit={registrarUser}>
              <div className="form-group">
                <label for="correo">Correo</label>
                <input
                  type="email"
                  className="form-control"
                  id="correo"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="form-group">
                <label for="password">Contraseña</label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div className="form-group">
                <label for="nombres">Nombres y apellidos</label>
                <input
                  type="text"
                  className="form-control"
                  id="nombres"
                  placeholder="Ingrese sus nombres y apellidos"
                  onChange={(e) => setNombres(e.target.value)}
                  value={nombres}
                />
              </div>
              <div className="form-group">
                <label for="restablecer">Restablecer contraseña</label>
                <select
                  onChange={(e) => setRestablecer(e.target.value)}
                  className="form-select"
                  aria-label="Seleccionar"
                >
                  {restablecer ? (
                    <option value={true} selected="true">
                      Si
                    </option>
                  ) : (
                    <option value={false} selected="true">
                      No
                    </option>
                  )}
                  {restablecer ? (
                    <option value={false}>No</option>
                  ) : (
                    <option value={true}>Si</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label for="restablecer">Roles</label>
                {roles.length > 0 &&
                  roles.map(
                    (rol) =>
                      rol && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={rol.nombre}
                            id="flexCheckDefault"
                            onChange={handleRoleSelection}
                            checked={selectRol && selectRol.includes(rol.nombre)}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {rol.nombre}
                          </label>
                        </div>
                      )
                  )}
              </div>
              <button className="btn btn-dark btn-block mb-4">Registrar</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrearUsuario;
